<template>
  <el-card shadow="always" v-loading="isLoading">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Receitas de Produtos</h4>
        <el-col :md="18">
          <el-row :gutter="20" type="flex" justify="end"> </el-row>
        </el-col>
      </el-row>
    </template>
    <el-descriptions
      :column="5"
      size="large"
      border
      direction="vertical"
      title=""
    >
      <el-descriptions-item
        v-for="ascending in AscendingProducts"
        :key="ascending?.uid"
      >
        <template #label>{{ ascending?.name }}:</template>
        {{
          ascending?.descending?.reduce(
            (t, { weight_percent }) => t + Number(weight_percent),
            0
          )
        }}
        %
      </el-descriptions-item>
    </el-descriptions>
    <el-table
      stripe
      :data="Products"
      style="width: 100%; z-index: 0"
      :cell-style="
        () => 'text-align:center;padding-left: 2px;padding-right:2px;'
      "
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column label="% do peso do animal">
        <template #default="{ row: product }">
          <base-input
            type="money"
            v-model="product.weight_percent"
            v-on:keyup.enter="
              updateProduct({
                uid: product.uid,
                weight_percent: product.weight_percent,
              })
            "
          >
            <template #append>%</template>
          </base-input>
        </template>
      </el-table-column>
      <el-table-column label="% preço mínimo">
        <template #default="{ row: product }">
          <base-input
            type="money"
            v-model="product.min_price"
            v-on:keyup.enter="
              updateProduct({ uid: product.uid, min_price: product.min_price })
            "
          >
            <template #append>%</template>
          </base-input>
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="{ row: product }">
          <el-select
            placeholder="Selecione o produto."
            :modelValue="product.ascending?.uid"
            size="medium"
            clearable
            @change="(v) => updateProduct({ uid: product.uid, product_id: v })"
          >
            <template #prefix>Ascendente:</template>
            <el-option
              v-for="item in Products"
              :key="item.uid"
              :label="item.name"
              :value="item.uid"
            >
            </el-option> </el-select
        ></template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
import BaseInput from "../components/BaseInput.vue";
import ProductService from "../services/products";
import { notifyError } from "../utils/notifiers";
export default {
  name: "ProductReceiptPage",
  components: { BaseInput },
  data: () => ({
    hasError: false,
    isLoading: true,
    products: null,
  }),
  computed: {
    Products() {
      return this.products;
    },
    AscendingProducts() {
      return this.Products?.filter(byProductWithDescendingProducts);
    },
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    async fetchProducts() {
      const { products } = await ProductService().index({
        category_id: "2ce1eca4-684a-4b57-a574-ba0cfb979517",
      });

      if (products) this.products = products;

      this.isLoading = false;
    },
    async updateProduct(_product) {
      const { error } = await ProductService(_product.uid).update(_product);

      if (error) notifyError("Não foi possível atualizar o produto");
      else this.fetchProducts();
    },
  },
};
const byProductWithDescendingProducts = (product) =>
  !!product?.descending?.length && !product?.ascending;
</script>
<style scoped>
.el-card {
  width: 100%;
}
.el-button {
  margin-left: 10px;
}
.el-autocomplete {
  margin-top: 0px !important;
  padding-top: 0px !important;
  display: block !important;
}
.header-color {
  background-color: #c0c4cc !important;
}
</style>
